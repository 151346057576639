import React, { useState, useEffect } from "react";
import Tree from "react-d3-tree";

const OrganizationChart = () => {
  const [organizationData, setOrganizationData] = useState(null);

  useEffect(() => {
    fetch("/organization-chart")
      .then((response) => response.json())
      .then((data) => setOrganizationData(data.organization));
  }, []);

  if (!organizationData) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        Fetching data :)
      </div>
    );
  }

  const treeData = {
    name: "Organization Chart",
    children: organizationData.departments.map((department) => ({
      name: department.name,
      attributes: { managerName: department.managerName },
      children: department.employees.map((employee) => ({
        name: employee.name,
        attributes: {
          department: employee.department,
          salary: employee.salary,
          office: employee.office,
          isManager: employee.isManager,
          skills: employee.skills.join(", "),
        },
      })),
    })),
  };

  const treeConfig = {
    nodeSize: { x: 100, y: 100 },
  };

  return (
    <div style={{ height: "100vh" }}>
      <h1 style={{ textAlign: "center" }}>Organization Chart</h1>
      <h2 style={{ textAlign: "center" }}>Move around and toggle the nodes!</h2>
      <Tree
        data={treeData}
        orientation="vertical"
        zoom={0.7}
        separation={{ siblings: 2, nonSiblings: 2 }}
        translate={{ x: 50, y: 50 }}
        initialDepth={2}
        shouldCollapseNeighborNodes={true}
        collapsible={true}
        tree={treeConfig}
      />
    </div>
  );
};

export default OrganizationChart;
